
.img {
   width: 200px;
   height: 120px;
}

@media screen and (max-width: 767px) {
  
 .img {
   width: 100%;
   height: 50px;
 }
    
}