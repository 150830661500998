
.givingcard {
   background-color: #fff;
}

#givingformid {
   font-size: 16px; 
   background-color:transparent; 
   border-color: #ddd;
   color:#000;
}

#givingformid :not(active){
   color:#000;
}

#givingformid :focus{
   color: #000;;
}

#givinginputid {
 font-size: 16px; 
 background-color:transparent; 
 border-color: #ddd;
 color:#000;
}

#givinginputid :not(active){
 color:#000;
}

#givinginputid :focus{
 color: #000;
}

#givingbtn {
   background-color: #fff;
   color: #000;
   font-weight: bold;
   font-size: 15px;
   border-radius: 0px;
   border: 1px solid #ddd;
   padding: 10px;
   width: 100%;
   margin-left: 2px;
   margin-right: 2px;
}

.paypalbuttonshow {
  display: block;
}

.paypalbuttonhide {
  display: none;
}


.paypalbuttonshowcard {
 display: block;
}

.paypalbuttonhidecard {
 display: none;
}

#showhidegivingcat, #givinginputid {
 display: none;
 width: 100%;
}

@media screen and (max-width: 767px) {

 #givingbtn {
   
 }
    
}