#frameid {
    width: 100%;
    height: 500px;
    margin: auto;
  }
  
  #frameid2 {
    width: 100%;
    height: 500px;
    margin: auto;
  }

  .livetext h6  {
    font-size: 15px;
  }

  .livetext p  {
    font-size: 15px;
  }

  .livebtn p {
    text-align: center;
  }
  
  
  @media screen and (max-width: 767px) {
  
      #frameid,#frameid2 {
          width: 100%;
          height: 100%;
          margin: auto;
      }
  
  }