
.foodbankimg {
    width: 100%;
    height: 50px;
}

  @media screen and (max-width: 767px) {
   
 
  
  }