#footer p {
  color:#fff;
  font-weight: 500;
  font-size: 15px;
}

#footerbtn {
   background-color: #fff;
   color:red;
   border-radius: 0;
   border: none;
   font-size: 16px;
}

#footer h5 {
   color:#fff;
   font-weight: 700;
}

#footerlinks p #link {
   text-decoration: none;
   color: #fff;
   font-weight: 500;
}


@media screen and (max-width: 767px) {
     #quicklinks {
       margin-top: 40px; 
     }

     #footercol1 {
        text-align: center;
     }

     #footerlinks {
        text-align: center;
     }

     #letterid {
      margin-top: 30px;
      text-align: center;
     }
}