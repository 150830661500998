/* NavBar.module.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');


.slide .carousel-indicators {
   display: none;
}

.carousel .carousel-control-prev, .carousel .carousel-control-next {
  display: none;
}

#logo {
  width: 70px;
  height: 70px;
}

.mynavbar {
  font-family: 'Roboto', sans-serif;
  /*background-color: rgba(0, 0, 0, 0.5);*/
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}

.mynavbar.myactive {
  background-color: #fff;
}

.mynavbar.myactive .navLink {
  color: #135592;
  border: none;
}

.mynavbar.myactive .navLink:hover {
  color: #135592;
  font-weight: 500;

}

.mynavbar .navLink {
  color: #000;
  font-weight: 700;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  transition: color 0.3s, background-color 0.3s;
  text-decoration: none;
  border-radius: 0px;
  margin-left: 5px;
  margin-right: 5px;
  /*border: 1px solid #dddada;*/

}



.mynavbar .navLink:hover {
  background-color: red;
  color:#fff;
}

#givenavheadlink {
  padding-left:0px;
  padding-right: 0px;
}

#givenavlink {

  text-align: center;
  margin-left: 10px;
  margin-right:10px;
}
 


.navbar-light .navbar-toggler-icon {
 background-color: #fff;
 color:#fff;
 filter: invert(0%);
}

.navbar-light .navbar-toggler
{
  border-radius: 0;
  /*border-color: 1px solid #fff;
  color: #fff !important;*/
  /*background-color: transparent !important;*/
  
}

.navbar-light .navbar-toggler:focus {
  box-shadow: 0 0 0 0;
}



/* 
---------------------------------------------
Sub header
--------------------------------------------- 
*/

#subheadertop {
  color:#fff;
  font-weight: bold;
  text-align: center;
  
}

#subheadertop span,#subheadertop #icon {
  font-size: 16px;
  
}

#subheadernews {
  text-align: center;
  font-weight: 500;
  display: flex;
  justify-content:center;
  align-items: center;
  height: 20px;
}

#socialbtnnav {
  background-color: transparent;
  color: #fff;
  border: none;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 19px;
}

#subheaderbtn {
   background-color: transparent;
   border-radius: 0;
   border:none;
   font-size: 14px;
}

#subheaderlink {
  text-align: right;
}

.sub-headermiddle {
  background-color: #135592;
  width: 100%;

  top: 50px;
  z-index: 1;
  padding: 15px;
}

.sub-headerbottom {
  background-color: #323232;
  width: 100%;
 
  top: 100px;
  z-index: 1;
  padding: 15px;
}


.sub-header {
  background-color: #323232;
  width: 100%;
  z-index: 1111;
  padding: 15px;
}

.sub-header .left-content p {
  color: #fff;
  padding: 8px 0px;
  font-size: 13px;
}

.sub-header .right-icons {
  text-align: right;
  padding: 8px 0px;
}



#nextevent {
  text-align: left;
  font-weight: bold;
}

@media (min-width: 768px) and (max-width: 912px) {


  #nextevent {
    text-align: center;
    font-weight: bold;
    padding: 15px;
  }
  

  .mynavbar .navLink { 
    border: none;
    /*background-color: rgba(0, 0, 0, 0.5);*/
    background-color: #fff;
    color: #000;
  }
  

  .mynavbar.myactive {
    background-color: #fff;
  }

  #mynavbartoggle { 
    background-color: #fff;
    border-radius: 0;
  } 


  #responsive-navbar-nav {
     background-color: #fff;
     border-radius: 10px;
     padding-left:20px;
     padding-right: 20px;
     box-shadow: 0 4px 8px 0 rgba(168, 6, 6, 0.2);
  }

  #navLink {
    color:#000;
  }

  #navLink:hover {
    background-color: red;
    color:#fff;
  }

  #navLink {
    margin: 0;
    background-color: transparent;
    color:#000;
  }

  #givenavheadlink {
    padding-left:5px;
    padding-right: 5px;
  }

  #givenavlink {
    width: 100%;
    text-align: center;
  } 

  .sub-header {
    z-index: 1;
    padding: 8px;
 
  }

  #sub-header {
    background-color: #323232;
  }

  #subheadernews {
    text-align: center;
    font-weight: 600;
    font-size: 12px;

  }

  #subheadermiddle {
    text-align: center;
    font-weight: 600;
    font-size: 10px;

  }

  #subheaderbtn {
    border: none;
    font-size: 12px;
    border: 1px solid #7d7c7c;
 }
 
  #subheaderlink {
   text-align: center;
  }

  .mynavbar {
    margin-top: 220px;
  }

  .sub-header {
     padding: 20px;
  }

  #subheadertop {
    padding:0px
  }

  .sub-headermiddle {
    margin-top: 0px;
    padding: 20px;
 }

  .sub-headerbottom {
     margin-top: 0px;
     padding: 20px;
  }

}

@media (min-width:913px) and (max-width: 1024px) {

  #nextevent {
    margin-top: -40px;
    text-align: center;
    padding:15px;
}


  .sub-header {
     padding: 20px;
  }

  .sub-headermiddle {
    margin-top: 0px;
    padding: 20px;
 }

  .sub-headerbottom {
     margin-top: 0px;
     padding: 40px;
  }
}

@media (min-width:1px) and (max-width: 767px) {

  #nextevent {
    text-align: center;
    font-weight: bold;
    padding: 15px;
  }

  .sub-header {
     padding: 20px;
  }

  .sub-headermiddle {
    margin-top: 0px;
    padding: 20px;
 }

  .sub-headerbottom {
     margin-top: 0px;
     padding: 20px;
  }

  #subheadertop,#subheadertop #icon {
    font-size: 13px;
    
  }
  #subheadertop span,#subheadertop #icon {
    font-size: 13px;
    
  }
  #logo {
    width: 70px;
    height: 70px;
  }
}

@media screen and (max-width: 320px) {
   #logo {
     width: 70px;
     height: 70px;
   }
}

