
.donationcard {
  background-color: #fff;
}

#donationformid {
  font-size: 16px; 
  background-color:transparent; 
  border-color: #ddd;
  color:#000;
}

#donationformid :not(active){
  color:#000;
}

#donationformid :focus{
  color: #000;;
}

#donationinputid {
font-size: 16px; 
background-color:transparent; 
border-color: #ddd;
color:#000;
}

#donationinputid :not(active){
color:#000;
}

#donationinputid :focus{
color: #000;
}

#donationbtn {
  background-color: #fff;
  color: #000;
  font-weight: bold;
  font-size: 15px;
  border-radius: 0px;
  border: 1px solid #ddd;
  padding: 10px;
  width: 100%;
  margin-left: 2px;
  margin-right: 2px;
}

.paypalbuttonshow {
 display: block;
}

.paypalbuttonhide {
 display: none;
}


.paypalbuttonshowcard {
display: block;
}

.paypalbuttonhidecard {
display: none;
}

#showhidedonationcat, #donationinputid {
display: none;
width: 100%;
}

@media screen and (max-width: 767px) {

#donationbtn {
  
}
   
}