#pastorcard {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 0px;
    width: 300px;
    background-color: #5c6063;
    color: #fff;
    margin: auto;
}

#pastorcard #pastorimg {
    width: 100%;
    height: 350px;
}

#aboutvidcol {
    background-color: #000;
    height: 500px;
}

#video {
    height: 500px;
}

#welcome p {
 font-size: 17px;
}

.homeabout p {
 font-size: 17px;
}


@media screen and (max-width: 767px) {
    #welcome {
        text-align: center;
    }

    #pastorcard {
        margin-top: 5px;
        margin-bottom: 5px;
        width: 100%;
    }

    #aboutvidcol {
        background-color: transparent;
        height: 100%;
    }

    #video {
        height: 100%;
    }
}